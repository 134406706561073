<template>
    <div class="tabbar">
        <!-- <div @click="selectTabItemBtn(item.url)" v-for="(item,index) in tabbars" :key="index" :class="`tab-bar-item ${selectitem==item.url?'activity':''}`">
            <div :class="`iconfont ${item.icon} item-icon`"></div>
            <div class="item-text">{{item.label}}</div>
        </div> -->
        <a-menu
          :mode="mode"
        >
          <template v-for="(item,index) in tabbars">

            <a-menu-item v-if="!item.items" @click="selectTabItemBtn(item.url)" :key="index">
              <icon-font :type="item.icon" style="fontSize:18px;"/>
              <span>{{$t(item.label)}}</span>
            </a-menu-item>

            <a-sub-menu v-if="item.items" :key="index">
              <span slot="title">
                <icon-font :type="item.icon" style="fontSize:18px;"/>
                <span>{{$t(item.label)}}</span>
              </span>
              <template v-for="(v,k) in item.items">
                <a-menu-item ::key="`Menu${i}`" @click="selectTabItemBtn(v.url)">
                  {{$t(v.label)}}
                </a-menu-item>
              </template>
            </a-sub-menu>

          </template>
        </a-menu>
         <!-- <a-dropdown style="margin-left:15px;color: rgba(0, 0, 0, 0.65);">
          <a class="ant-dropdown-link" @click="handleMenuClick">
            <icon-font type="icon-shezhi" style="fontSize:18px;"/>
            Setting
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button class="ant-btn-danger" @click="loginoutBtn"  type="primary">
              LoginOut
              </a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
    </div>
</template>

<script>
import {Icon} from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: 'https://at.alicdn.com/t/font_2233681_vez8folbkcg.js',
});
export default {
    name: 'NodeprojectTabbar',
    components: {IconFont},
    directives: {  },
    data() {
        return {
            mode:'inline',
            collapsed: false,
            selectitem:'/home',
            tabbars:[
          {
            url:'/home',
            label: 'tabBar.home',
            icon: 'icon-home',
            
          },{
            url:'/inventory',
            label: 'tabBar.inventory',
            icon: 'icon-shengqian',
            
          },
          {  
            url:'/inbound',
            label: 'tabBar.inbound',
            icon: 'icon-shengqian',
            items:[
              {
                url:'/inbound/add',
                label: 'tabBar.create_inbound_order',
                icon: 'icon-shengqian'
              },{
                url:'/inbound/list',
                label: 'tabBar.all_inbound_ordersome',
                icon: 'icon-shengqian'
              }
            ]
          },{
            url:'/products2/browse',
            label: 'product.list.btn_2',
            icon: 'icon-shengqian',
          },
          {
            url:'/products2',
            label: 'tabBar.products',
            icon: 'icon-shengqian',
          },
          // { 
          //   url:'/orders',
          //   label: 'Orders',
          //   icon: 'icon-dingdan',
          // },
          // {
          //   url:'/productdemand',
          //   label: 'Product Demand',
          //   icon: 'icon-yunshu',
          // },
          {
            url:'/orders3',
            label: 'tabBar.orders',
            icon: 'icon-gouwuche1',
          },
          {
            url:'/shopify',
            label: 'tabBar.shopify',
            icon: 'icon-shopify1',
          },
          { 
            url:'/stores',
            label: 'tabBar.stores',
            icon: 'icon-store',
          },
          { 
            url:'/companyprofile',
            label: 'tabBar.company_profile',
            icon: 'icon-company',
          },
          { 
            url:'/media',
            label: 'tabBar.media',
            icon: 'icon-Media-folder',
          },
          { 
            url:'/pressandnews',
            label: 'tabBar.press_and_news',
            icon: 'icon-news',
          },
          { 
            url:'/notifications',
            label: 'tabBar.notifications',
            icon: 'icon-iconbell',
          },
          {
            url:'/setting',
            label: 'tabBar.setting',
            icon: 'icon-shezhi',
          },
          // {
          //   url:'/users',
          //   label: 'users',
          //   icon: 'icon-UserService',
          // },
        ]
        };
    },
    mounted() {
        
    },
    methods: {
        selectTabItemBtn(e){
            this.$router.replace(e).catch(err=>err)
            this.selectitem=e
        },
        toggleCollapsed(){
          this.collapsed = !this.collapsed;
        },
        handleMenuClick(e){
          console.log('click', e);
        },
        
    },
};
</script>

<style  scoped>
.tabbar{
  width: 100%;
}
/* .tabbar{display: flex;flex-direction: column;width: 100%;}
.tabbar .tab-bar-item{padding:10px;cursor: pointer;font-size: 20px;color: #62628d;display: flex;align-items: center;}
.tabbar .item-icon{font-size: 20px;}
.tabbar .item-text{font-size: 16px;margin-left: 6px;}
.tabbar .activity{color: #dd4d76;background-color: #ff105426;}

.tabbar .logo{margin-bottom: 24px;position:sticky;top: 0;left: 0;z-index: 1;}
.tabbar .logo img{width: 100%;height: 100%;}
.tabbar .pages{width: 100%;}
.tabbar .nav_item{padding: 1px;display: flex;line-height: 32px;text-decoration: none;flex-direction: row;align-items: center;min-width: 0;max-width: 100%;padding-left: 15px;font-weight: 500;text-align: left;
letter-spacing: normal;color: #62628d;cursor: pointer;}
.tabbar .nav_item:hover{color: #040c3b;}
.tabbar .nav_item_s{color: #dd4d76;background-color: #ff105426;}
.tabbar .nav_icon{width: 20px;height: 20px;line-height: 20px;margin-right: 8px;font-size: 20px;}
.tabbar .nav_text{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 100%;font-weight: 500;} */

</style>