export default {
    tabBar: {
        home:'主页',
        inventory:'库存',
        inbound:'退货',
        create_inbound_order:'创建退货订单',
        all_inbound_ordersome:'所有退货订单',
        products:'产品',
        orders:'订单',
        shopify:'Shopify',
        stores:'商店',
        company_profile:'公司简介',
        media:'媒体',
        press_and_news:'新闻',
        notifications:'通知',
        setting:'设置',
    },
    message:{
        infoMax300:'输入最大300字',
        info:'请输入!',
        delete:'您确定要删除吗?',
        btn_ok:'是的',
        btn_no:'否',
    },
    action:{
        edit:'编辑',
        cancel:'取消',
        delete:'删除',
        canceled:'已取消',
    },
    form:{
        select_info:'请选择!',
        btn_submit:'保存',
        add:'添加',
        edit:'编辑',
        btn_back:'返回',
        select_yes:'是',
        select_no:'否',
    },
    login:{
        btn_1:'登陆',
        email:'Email',
        password:'密码',
        item_1:'没有账号 ? ',
        item_2:'注册',
        item_3:'忘记密码?',
    },
    register:{
        title:'注册',
        email:'Email',
        brand_name:'品牌名',
        password:'密码',
        item_1:'有账号?',
        item_2:'登陆',
    },
    resetpassword:{
        title:'重置密码',
        email_msg:'请输入 Email',
        item_1:'验证码',
        item_1_msg:'请输入!',
        getCode:'获取',
        item_2:'新密码',
        item_2_msg:'请输入',
        item_3:'有账号 ?',
        item_4:'登陆',
    },
    home: {
        company_profile:'公司简介',
        brand_name:'品牌名',
        contacts:'联络',
        contacts_number:'联系人号码',
        company_address:'公司地址',
        email:'电子邮件',
        website:'网站',

        more:'更多',
        products:'产品',
        press_and_news:'新闻',
        media:'媒体',
    },
    inventory:{
        titel:'库存',
        search:{
            condition:'状态',
            inventory_status:'库存状态',
            sku:'SKU',
            product_name:'产品名字',
            search:'筛选',
            all:'全部',
            pre_owned:'准新',
            used:'二手',
            brand_new:'全新的',
            in_stock:'库存',
            in_inspection:'检查中',
            pending_to_receive:'等待接收',
        },
        all:'全部',
        tab_3:'供应商',
        table:{
            image:'图片',
            sku:'SKU',
            product_name:'产品名',
            inventory_status:'库存状态',
            condition:'状态',
            unit:'库存',
            action:'操作',
        }
    },
    inbound:{
        add:{
            titel:'退货',
            tab_1:'创建单个退货订单',
            tab_2:'创建批量退货订单',

            import:'导入',
            import2:'从MKP订单导入买家地址信息',
            select_defautl:'请选择',
            item_1:'MKP订单ID',
            item_2:'用户记录id',
            item_3:'SKU',
            item_4:'数量',
            item_5:'买方全名',
            item_6:'电话',
            item_7:'地址1',
            item_8:'地址2',
            item_9:'城市/城镇',
            item_10:'州',
            item_11:'县',
            item_12:'邮编',

            item_13:'重量',
            item_14:'长度',
            item_15:'宽度',
            item_16:'高度',

            item_17:'仓库',
            item_18:'服务类型',
            item_19:'运单号',
            item_20:'退货原因',
            item_21:'创建订单',
            item_22:'采购发货标签',
        },
        list:{
            titel:'所有退货订单',

            search:{
                title:'筛选',
                item_1:'发货状态',
                item_2:'创建日期',
                item_3:'库存状态',
                item_4:'交货日期',
            },
            tab_1:'全部',
            tab_3:'供应商',
            table:{
                head_titel_1:'创建日期',
                head_titel_2:'RMA',
                head_titel_3:'仓库序列号',
                head_titel_4:'用户记录ID',
                head_titel_5:'发货状态',
                head_titel_6:'交货日期',
                head_titel_7:'查询号',
                head_titel_8:'仓库',
                head_titel_9:'库存状态',
                head_titel_10:'运费',
                head_titel_11:'货运状态',
                head_titel_12:'退货原因',
            }
        },
        all:'全部',
        freight_status_text:{
            paid:'支付',
            unpaid:'未付款',
        },
        shipment_status_text:{
            pending:'待交付',
            delivered:'交付',
        },
        inventory_status_text:{
            in_stock:'库存',
            in_inspection:'检查中',
            pending_to_receive:'等待接收',
        },
    },
    product:{
        browse:{
            btn_1:'编辑',
            btn_2:'点击申请入驻Target',
            btn_3:'正在审核（单击查看）',
            btn_4:'点击取消提交',
        },
        list:{
            titel:"产品",
            search:{
                sku:'SKU',
                product_name:'产品名',
                condition:'状态',
                search:'筛选',
                all:'全部',
            },
            btn_1:'添加产品',
            btn_2:'浏览产品',
            tab_1:'全部',
            tab_3:'供应商',
            table:{
                head_titel_1:'图片',
                head_titel_2:'产品名',
                head_titel_3:'SKU',
                head_titel_4:'价格',
                head_titel_5:'库存',
                head_titel_6:'重量(Lbs)',
                head_titel_7:'长度(Inches)',
                head_titel_8:'宽度(Inches)',
                head_titel_9:'高度(Inches)',
                head_titel_10:'状态',
                head_titel_11:'操作',
            }

        },
        add:{
            lable_1:'产品名',
            lable_2:'价格',
            lable_3:'库存',
            lable_4:'重量',
            lable_5:'长度',
            lable_6:'宽度',
            lable_7:'高度',
            lable_4_2:'包装重量',
            lable_5_2:'包装长度',
            lable_6_2:'包装宽度',
            lable_7_2:'包装高度',
            lable_8:'图片',
            lable_9:'描述',
            lable_10:'卖点1',
            lable_11:'卖点2',
            lable_12:'卖点3',
            lable_13:'卖点4',
            lable_14:'卖点5',
            lable_15:'卖点6',
            lable_16:'卖点7',
            lable_17:'简单描述',
            lable_18:'展示产品',
            lable_19:'状态',
            btn_submit:'保存',
        },
    },
    orders:{
        title:'订单',
        search:{
            item_1:'下单日期',
            item_2:'下单日期',
            item_3:'产品代码',
            item_4:'筛选',
            item_5:'下载',
        },
        tab_1:'全部',
        tab_3:'供应商',
        tab_2_btn:'创建手动订单',
        alert:{
            title:'你确定删除这个订单?',
            ok_text:'是',
            cancel_text:'否',
        },
        table:{
            action:{
                edit:'编辑',
                cancel:'取消',
                delete:'删除',
                canceled:'已取消',
            },
            head_titel_1:'下单日期',
            head_titel_2:'图片',
            head_titel_3:'产品代码',
            head_titel_4:'价格',
            head_titel_5:'订单号',
            head_titel_6:'产品型号',
            head_titel_7:'国家',
            head_titel_8:'收件人',
            head_titel_9:'收件人地址',
            head_titel_10:'电话号码',
            head_titel_11:'邮编',
            head_titel_12:'数量',
            head_titel_13:'发货日期',
            head_titel_14:'物流渠道/服务商',
            head_titel_15:'物流差价',
            head_titel_16:'物流跟踪号',
            head_titel_17:'操作',
        },
        model:{
            title:'添加信息',
            item_1:'交货时间',
            item_2:'物流渠道/服务提供商',
            item_3:'物流跟踪编号',
            item_4:'物流价差',
            item_5:'点击上传',
            item_6:'取消原因',
            item_7:'已发货',
            item_8:'未发货',
            item_9:'面单',
            item_10:'发货？',
            cancel:'取消',
        },
        add:{
            title:'添加',
            alert_msg:'请输入!',
            item_1:'下单日期',
            item_2:'产品代码',
            item_3:'订单号',
            item_4:'产品型号',
            item_5:'国家',
            item_6:'收件人',
            item_7:'收件人地址',
            item_8:'电话号码',
            item_9:'邮编',
            item_10:'数量',
            item_11:'发货日期',
            item_12:'物流渠道/服务商',
            item_13:'物流跟踪号',
            item_14:'面单',
            btn_submit:'保存',
        },
        edit:{
            title:'编辑',
            alert_msg:'请输入!',
            item_1:'下单日期',
            item_2:'产品代码',
            item_3:'订单号',
            item_4:'产品型号',
            item_5:'国家',
            item_6:'收件人',
            item_7:'收件人地址',
            item_8:'电话号码',
            item_9:'邮编',
            item_10:'数量',
            item_11:'发货日期',
            item_12:'物流渠道/服务商',
            item_13:'物流跟踪号',
            item_14:'面单',
            btn_submit:'保存',
            btn_upload:'点击上传',
        }
    },
    shopify:{
        btn_add:'添加',
    },
    store:{
        title:'商店',
    },
    companyprofile:{
        title:'公司简介',
        setInfo:'完善信息',
        item_0:'Logo',
        item_1:'公司名称',
        item_2:'联络人',
        item_3:'联系人号码',
        item_4:'公司地址',
        item_5:'电子邮件',
        item_6:'网站',
        item_7:'简介',
        item_8:'产品手册(ppt)',
        item_9:'文件封面图',
        btn_submit:'保存',
        btn_upload:'上传',
    },
    medialist:{
        title:'媒体',
        btn_add:'添加',
        add:{
            item_1:'类型',
            item_2:'标题',
            item_3:'链接',
            item_4:'描述',
            item_5:'图片',
        }
    },
    newslist:{
        title:'新闻',
        btn_add:'添加',
        table:{
            head_titel_1:'图片',
            head_titel_2:'标题',
            head_titel_3:'作者',
            head_titel_4:'关键字',
            head_titel_5:'时间',
            head_titel_6:'状态',
            head_titel_7:'操作',
        },
        add:{
            item_1:'标题',
            item_2:'作者',
            item_3:'关键字',
            item_4:'时间',
            item_5:'图片',
            item_6:'内容',
            btn_submit:'保存',
        }
    },
    notifications:{
        title:'通知',
    },
    setting:{
        title:'设置',
        btn_1:'登录退出',
    }
}