export default {
    tabBar: {
        home:'Home',
        inventory:'Inventory',
        inbound:'Inbound',
        create_inbound_order:'Create Inbound Order',
        all_inbound_ordersome:'All Inbound Ordersome',
        products:'Products',
        orders:'Orders',
        shopify:'Shopify',
        stores:'Stores',
        company_profile:'Company Profile',
        media:'Media',
        press_and_news:'Press and News',
        notifications:'Notifications',
        setting:'Set',
    },
    message:{
        infoMax300:'Length max 300',
        info:'Please input!',
        delete:'Are you sure delete?',
        btn_ok:'Yes',
        btn_no:'No',
    },
    action:{
        edit:'Edit',
        cancel:'Cancel',
        delete:'Delete',
        canceled:'Cancelled',
    },
    form:{
        select_info:'Please select!',
        btn_submit:'Submit',
        add:'Add',
        edit:'Edit',
        btn_back:'back',
        select_yes:'yes',
        select_no:'no',
    },
    login:{
        btn_1:'LOG IN',
        email:'Email',
        password:'Password',
        item_1:'no account ? ',
        item_2:'sign up',
        item_3:'Forgot password?',
    },
    register:{
        title:'Sign up',
        email:'Email',
        brand_name:'Brand Name',
        password:'Password',
        item_1:'has account ?',
        item_2:'login',
    },
    resetpassword:{
        title:'Reset Password',
        email_msg:'Please input your Email',
        item_1:'Code',
        item_1_msg:'Please input the Code!',
        getCode:'Get Code',
        item_2:'New Password',
        item_2_msg:'Please input your New Password',
        item_3:'has account ?',
        item_4:'login',
    },
    home: {
        company_profile:'Company Profile',
        brand_name:'Brand name',
        contacts:'Contacts',
        contacts_number:'Contacts number',
        company_address:'Company address',
        email:'E-mail',
        website:'Website',

        more:'more',
        products:'Products',
        press_and_news:'Press and News',
        media:'Media',
        

    },
    inventory:{
        titel:'Inventory',
        search:{
            condition:'Condition',
            inventory_status:'Inventory Status',
            sku:'SKU',
            product_name:'Product Name',
            search:'Search',
            all:'All',
            pre_owned:'Pre-Owned',
            used:'Used',
            brand_new:'Brand New',
            in_stock:'In-Stock',
            in_inspection:'In Inspection',
            pending_to_receive:'Pending to Receive',
        },
        all:'All',
        tab_3:'Supplier',
        table:{
            image:'Image',
            sku:'SKU',
            product_name:'Product Name',
            inventory_status:'Inventory Status',
            condition:'Condition',
            unit:'Unit',
            action:'Action',
        }
    },
    inbound:{
        add:{
            titel:'Inbound',
            tab_1:'Creat Single Return Shipment',
            tab_2:'Creat Batch Restock Shipment',
            import:'Import',
            import2:'Import buyer address information from MKP Order',
            select_defautl:'Please choose',
            item_1:'MKP Order ID',
            item_2:'User record id',
            item_3:'SKU',
            item_4:'Qty',
            item_5:'Buyer fullname',
            item_6:'Phone',
            item_7:'Address line 1',
            item_8:'Address line 2',
            item_9:'City/Town',
            item_10:'State',
            item_11:'County',
            item_12:'Zip/Postcode',
            item_13:'Weight',

            item_14:'Length',
            item_15:'Width',
            item_16:'Height',
            item_17:'Warehouse',
            item_18:'Service Type',
            item_19:'Tracking Number',
            item_20:'Reason for return',
            item_21:'Create Order',
            item_22:'Purchase Shipment Label',
        },
        list:{
            titel:'All Inbound Orders',

            search:{
                title:'Search',
                item_1:'Shipment Status',
                item_2:'Create Date',
                item_3:'Inventory Status',
                item_4:'Delivered Date',
            },
            tab_1:'All',
            tab_3:'Supplier',
            table:{
                head_titel_1:'Create Date',
                head_titel_2:'RMA',
                head_titel_3:'Warehouse SN',
                head_titel_4:'User Record ID',
                head_titel_5:'Shipment Status',
                head_titel_6:'Delivered Date',
                head_titel_7:'Tracking Number',
                head_titel_8:'Warehouse',
                head_titel_9:'Inventory Status',
                head_titel_10:'Freight',
                head_titel_11:'Freight Status',
                head_titel_12:'Reason for return',
            }
        },
        all:'All',
        freight_status_text:{
            paid:'Paid',
            unpaid:'Unpaid',
        },
        shipment_status_text:{
            pending:'Pending',
            delivered:'Delivered',
        },
        inventory_status_text:{
            in_stock:'In-Stock',
            in_inspection:'In Inspection',
            pending_to_receive:'Pending to Receive',
        },
    },
    product:{
        browse:{
            btn_1:'Edit',
            btn_2:'Submit to Target+',
            btn_3:'Under Review (Click to retrive)',
            btn_4:'Click to cancel submission',
        },
        list:{
            titel:"Products",
            search:{
                sku:'SKU',
                product_name:'Product Name',
                condition:'Condition',
                search:'Search',
                all:'All',
            },
            btn_1:'Add Product',
            btn_2:'Browse Products',
            tab_1:'All',
            tab_3:'Supplier',
            table:{
                head_titel_1:'Image',
                head_titel_2:'Product Name',
                head_titel_3:'SKU',
                head_titel_4:'MRPS',
                head_titel_5:'Unit',
                head_titel_6:'Weight(Lbs)',
                head_titel_7:'Length(Inches)',
                head_titel_8:'Width(Inches)',
                head_titel_9:'Height(Inches)',
                head_titel_10:'Condition',
                head_titel_11:'Action',
            }

        },
        add:{
            lable_1:'Product Name',
            lable_2:'MRPS',
            lable_3:'Unit',
            lable_4:'Weight',
            lable_5:'Length',
            lable_6:'Width',
            lable_7:'Height',
            lable_4_2:'PackWeight',
            lable_5_2:'PackLength',
            lable_6_2:'PackWidth',
            lable_7_2:'PackHeight',
            lable_8:'Image',
            lable_9:'Description',
            lable_10:'Selling point 1',
            lable_11:'Selling point 2',
            lable_12:'Selling point 3',
            lable_13:'Selling point 4',
            lable_14:'Selling point 5',
            lable_15:'Selling point 6',
            lable_16:'Selling point 7',
            lable_17:'Simple description',
            lable_18:'Browse Product',
            lable_19:'Condition',
            btn_submit:'Submit',
        }
    },
    orders:{
        title:'Orders',
        search:{
            item_1:'Order Date',
            item_2:'Order Date',
            item_3:'Product Code',
            item_4:'Search',
            item_5:'Dowload',
        },
        tab_1:'All',
        tab_3:'Supplier',
        tab_2_btn:'CREATE MANUAL ORDER',
        alert:{
            title:'Are you sure delete this order?',
            ok_text:'Yes',
            cancel_text:'No',
        },
        table:{
            action:{
                edit:'Edit',
                cancel:'Cancel',
                delete:'Delete',
                canceled:'Cancelled',
            },
            head_titel_1:'Order Date',
            head_titel_2:'Image',
            head_titel_3:'Product Code',
            head_titel_4:'Cost',
            head_titel_5:'Order Number',
            head_titel_6:'Product Model',
            head_titel_7:'Country',
            head_titel_8:'Address',
            head_titel_9:'Recipient Address',
            head_titel_10:'Telephone Number',
            head_titel_11:'Postal code',
            head_titel_12:'Quantity',
            head_titel_13:'Shipped Date',
            head_titel_14:'Logistics Channel/Service Provider',
            head_titel_15:'Logistics price difference',
            head_titel_16:'Logistics Tracking Number',
            head_titel_17:'Action',
        },
        model:{
            title:'Add Info',
            item_1:'Delivery Time',
            item_2:'Logistics Channel/Service Provider',
            item_3:'Logistics Tracking Number',
            item_4:'Logistics price difference',
            item_5:'Click to Upload',
            item_6:'Reason for cancellation',
            item_7:'Shipped',
            item_8:'Unshipped',
            item_9:'Unilateralism',
            item_10:'Deliver goods?',
            cancel:'Cancel',
        },
        add:{
            title:'Add',
            alert_msg:'Please input!',
            item_1:'Order Date',
            item_2:'Product Code',
            item_3:'Order Number',
            item_4:'Product Model',
            item_5:'Country',
            item_6:'Address',
            item_7:'Recipient Address',
            item_8:'Telephone Number',
            item_9:'Postal code',
            item_10:'Quantity',
            item_11:'Shipped Date',
            item_12:'Logistics Channel/Service Provider',
            item_13:'Logistics Tracking Number',
            item_14:'Unilateralism',
            btn_submit:'Submit',
        },
        edit:{
            title:'Edit',
            alert_msg:'Please input!',
            item_1:'Order Date',
            item_2:'Product Code',
            item_3:'Order Number',
            item_4:'Product Model',
            item_5:'Country',
            item_6:'Address',
            item_7:'Recipient Address',
            item_8:'Telephone Number',
            item_9:'Postal code',
            item_10:'Quantity',
            item_11:'Shipped Date',
            item_12:'Logistics Channel/Service Provider',
            item_13:'Logistics Tracking Number',
            item_14:'Unilateralism',
            btn_submit:'Submit',
            btn_upload:'Click to Upload',
        }
    },
    shopify:{
        btn_add:'Add New',
    },
    store:{
        title:'Stores',
    },
    companyprofile:{
        title:'Company Profile',
        setInfo:'Improve information',
        item_0:'Logo',
        item_1:'Company name',
        item_2:'Contacts',
        item_3:'Contacts number',
        item_4:'Company address',
        item_5:'E-mail',
        item_6:'Website',
        item_7:'About',
        item_8:'File(ppt)',
        item_9:'File cover',
        btn_submit:'Submit',
        btn_upload:'Upload',
    },
    medialist:{
        title:'Media',
        btn_add:'Add Media',
        add:{
            item_1:'Type',
            item_2:'Title',
            item_3:'URL',
            item_4:'Description',
            item_5:'Image',
        }
    },
    newslist:{
        title:'Press and News',
        btn_add:'Add News',
        table:{
            head_titel_1:'Img',
            head_titel_2:'Title',
            head_titel_3:'Author',
            head_titel_4:'Keywords',
            head_titel_5:'Date',
            head_titel_6:'Status',
            head_titel_7:'Action',
        },
        add:{
            item_1:'Title',
            item_2:'Author',
            item_3:'Keywords',
            item_4:'Date',
            item_5:'Feature Image',
            item_6:'Content',
            btn_submit:'Submit',
        }
    },
    notifications:{
        title:'Notifications',
    },
    setting:{
        title:'Set',
        btn_1:'LoginOut',
    }
}