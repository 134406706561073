<template  >
   <div v-if="!$route.meta.isShowNav" id="app">
    <div  class="left">
      <div class="logo">
         <img src="@/assets/images/logo.png"/>
      </div>
      <TabBar/>
    </div>
    <div class="content">
      <div style="text-align: right;position: absolute;right: 15px;top: 15px;z-index: 999999;">
        <select v-model="$i18n.locale" style="border: 1px solid #ccc;outline: none;">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.k">
            {{ lang.v }}
          </option>
        </select>
      </div>
        <router-view/>
    </div>
  </div>
   <div  id="app" v-else >
      <div style="text-align: right;position: absolute;right: 15px;top: 15px;z-index: 999999;">
        <select v-model="$i18n.locale" style="border: 1px solid #ccc;outline: none;">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.k">
            {{ lang.v }}
          </option>
        </select>
      </div>
      <router-view/>
    </div>
</template>

<script>

import Cookies from 'js-cookie'
import TabBar from './components/common/tabbar/TabBar'
export default {
  name: 'App',
  components:{TabBar},
  data(){
    return {
      token:Cookies.get('token')||'',
      langs: [{k:'en',v:'English'},{k:'zh',v:'简体中文'}]
    }
  }
}
</script>


<style>


.logo{display: flex;align-items: center;justify-content: center;}
.logo img{width:192px;height:108px;}
.left{background-color: #fff;z-index: 99; position: fixed;left: 0;top: 0;bottom: 0;min-width:200px;border-right: 1px solid #eee;}
.content{padding: 5px 10px; padding-left:210px;}

.left{overflow-y: auto;}
.left::-webkit-scrollbar {/*滚动条整体样式*/
    width: 1px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.left::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 1px;
    /* box-shadow: inset 0 0 5px rgba(92, 88, 88, 0.1); */
    background: #ccc;
}

.left::-webkit-scrollbar-track{/*滚动条里面轨道*/
    /* box-shadow: inset 0 0 5px rgba(173, 166, 166, 0.1); */
    border-radius: 10px;
    background: #EDEDED;
}


  
</style>
