import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    maker_id:''
  },
  mutations: {
    updatemakerid(state,e){
      state.maker_id=e
    }
  },
  actions: {
  },
  modules: {
  }
})
