import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes=[
  {
    path:'',
    redirect:'/home'
  }, 
  {
    path:'/home',
    component:()=>import('@/views/home/Home'),
    children:[
      {
        path:'',
        component:()=>import('@/views/home/index')
      },
      {
        path:'productlist',
        component:()=>import('@/views/home/productlist')
      },
      {
        path:'productlisting',
        component:()=>import('@/views/home/productlisting')
      }
    ]
  },
  {
    path:'/inventory',
    component:()=>import('@/views/inventory/list')
  },{
    path:'/inbound',
    component:()=>import('@/views/inbound'),
    children:[
      {
        path:'add',
        component:()=>import('@/views/inbound/add')
      },
      {
        path:'list',
        component:()=>import('@/views/inbound/list')
      }
    ]
  },
  {
    path:'/products',
    component:()=>import('@/views/products/Products'),
    children:[
      {
        path:'',
        redirect:'/products/productlist'
      }, 
      {
        path:'productlist',
        component:()=>import('@/views/products/ProductList')
      },
      {
        path:'editproduct',
        name:'editproduct',
        component:()=>import('@/views/products/EditProduct')
      },
    ]
  },
  {
    path:'/products2',
    component:()=>import('@/views/products2/index'),
    children:[
      {
        path:'',
        redirect:'/products2/list'
      }, 
      {
        path:'list',
        component:()=>import('@/views/products2/list')
      },
      {
        path:'edit',
        component:()=>import('@/views/products2/edit')
      },
      {
        path:'browse',
        component:()=>import('@/views/products2/browse')
      },
    ]
  },
 {
    path:'/Product',
    component:()=>import('@/views/Product'),
    meta:{
      title:'product',
      isShowNav: true
    }
  },
   {
    path:'/findproduct',
    component:()=>import('@/views/findproduct/FindProduct')
  },
  // {
  //   path:'/products2',
  //   component:()=>import('@/views/products2/index'),
  //   children:[
  //     {
  //       path:'',
  //       redirect:'/products2/list'
  //     }, 
  //     {
  //       path:'list',
  //       component:()=>import('@/views/products2/list')
  //     },
  //     {
  //       path:'edit',
  //       component:()=>import('@/views/products2/edit')
  //     },
  //   ]
  // },
  {
    path:'/orders3',
    component:()=>import('@/views/orders3/index'),
    children:[
      {
        path:'',
        component:()=>import('@/views/orders3/Orders')
      },
      {
        path:'add',
        component:()=>import('@/views/orders3/add'),
      },
      {
        path:'edit',
        component:()=>import('@/views/orders3/edit'),
      }
    ]
  },
  {
    path:'/orders',
    component:()=>import('@/views/orders/Orders'),
  },
  {
    path:'/shopify',
    component:()=>import('@/views/shopify/index')
  }, {
    path:'/stores',
    component:()=>import('@/views/stores/Stores'),
    children:[
      {
        path:'',
        component:()=>import('@/views/stores/Storeslist')
      },
      {
        path:'detail',
        component:()=>import('@/views/stores/Detail')
      }
    ]
  }, {
    path:'/companyprofile',
    component:()=>import('@/views/companyprofile/CompanyProfile')
  }, {
    path:'/media',
    component:()=>import('@/views/media/Media'),
    children:[
      {
        path:'',
        redirect:'/media/medialist'
      },
      {
        path:'medialist',
        component:()=>import('@/views/media/MediaList')
      },
      {
        path:'editmedia',
        component:()=>import('@/views/media/EditMedia')
      },
    ]
  }, {
    path:'/users',
    component:()=>import('@/views/users/index'),
    children:[
      {
        path:'',
        redirect:'/users/list'
      },
      {
        path:'list',
        component:()=>import('@/views/users/list')
      },
      {
        path:'edit',
        component:()=>import('@/views/users/edit')
      },
    ]
  }, {
    path:'/orders2',
    component:()=>import('@/views/orders2/index'),
    children:[
      {
        path:'',
        redirect:'/orders2/Orders'
      },
      {
        path:'Orders',
        component:()=>import('@/views/orders2/Orders')
      },
      {
        path:'status',
        component:()=>import('@/views/orders2/status')
      },
      {
        path:'history',
        component:()=>import('@/views/orders2/history')
      },{
        path:'historyStatus',
        component:()=>import('@/views/orders2/historyStatus')
      },
    ]
  }
  , {
    path:'/pressandnews',
    component:()=>import('@/views/pressandnews/Pressandnews'),
    children:[
      {
        path:'',
        redirect:'/pressandnews/newslist'
      },
      {
        path:'newslist',
        component:()=>import('@/views/pressandnews/NewsList')
      },
      {
        path:'editnews',
        component:()=>import('@/views/pressandnews/Editnews')
      }
    ]
  }
  , {
    path:'/notifications',
    component:()=>import('@/views/notifications/Notifications')
  }, {
    path:'/setting',
    component:()=>import('@/views/setting/Setting')
  }
  , {
    path:'/makerappstore',
    component:()=>import('@/views/makerappstore/MakerAppStore')
  },
  {
    path:'/login',
    component:()=>import('@/views/login'),
    meta:{
      title:'Login',
      isShowNav: true
    }
  },{
    path:'/register',
    component:()=>import('@/views/register'),
    meta:{
      title:'Sign up',
      isShowNav: true
    }
  },{
    path:'/setInfo',
    component:()=>import('@/views/setInfo'),
    meta:{
      title:'set info',
      isShowNav: true
    }
  },{
    path:'/resetpassword',
    component:()=>import('@/views/resetpassword'),
    meta:{
      title:'Reset',
      isShowNav: true
    }
  }, {
    path:'/productdemand',
    component:()=>import('@/views/productdemand/index'),
    children:[
      {
        path:'',
        redirect:'/productdemand/list'
      },
      {
        path:'list',
        component:()=>import('@/views/productdemand/list')
      },
      {
        path:'edit',
        component:()=>import('@/views/productdemand/edit')
      }
    ]
  }
];
const router= new Router({
  routes,
  mode:'history'
});

import Cookies from 'js-cookie'
router.beforeEach((to,from,next)=>{
  if(Cookies.get('maker_id')||to.path=='/login'||to.path=='/register'||to.path=='/setInfo'||to.path=='/resetpassword'||to.path=='/product'){
      next();
      return false;
  }
  next('/login')
})

export default router

