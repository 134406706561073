import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import zh from './lang/zh'
import en from './lang/en'
Vue.use(VueI18n)
let i18n = new VueI18n({
//   locale: localStorage.getItem('language') || 'zh',
  locale: 'en',
  messages: {
    'zh': zh,
    'en': en,
  }
});
//引入ant-design-vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
require('@/assets/css/base.css')
require('@/assets/css/iconfont.css')

import vuelazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.config.productionTip = false
Vue.use(vuelazyload,{
  preLoad: 1.3,
  error: '',
  loading: '',
  attempt: 1
})

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
 
